var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.Loading)?_c('loading'):_vm._e(),_c('b-modal',{attrs:{"title":"Amandemen Pasal","hide-footer":"","size":"sm"},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('validation-observer',{ref:"news",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submitAdd($event)}}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitAdd($event)}}},[(_vm.Loading)?_c('loading'):_vm._e(),(!_vm.Loading)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Type"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"type","placeholder":"Type","locale":"id","now-button":"","state":errors.length > 0 ? false : null},model:{value:(_vm.formPasal.type),callback:function ($$v) {_vm.$set(_vm.formPasal, "type", $$v)},expression:"formPasal.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3772499685)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Pasal"}},[_c('validation-provider',{attrs:{"name":"pasal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"pasal","placeholder":"Pasal","locale":"id","now-button":"","state":errors.length > 0 ? false : null},model:{value:(_vm.formPasal.pasal),callback:function ($$v) {_vm.$set(_vm.formPasal, "pasal", $$v)},expression:"formPasal.pasal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1689581138)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"name","placeholder":"Name","locale":"id","now-button":"","state":errors.length > 0 ? false : null},model:{value:(_vm.formPasal.name),callback:function ($$v) {_vm.$set(_vm.formPasal, "name", $$v)},expression:"formPasal.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,488210298)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Lama Penjara"}},[_c('validation-provider',{attrs:{"name":"jail","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"jail","placeholder":"Lama Penjara","locale":"id","now-button":"","state":errors.length > 0 ? false : null},model:{value:(_vm.formPasal.jail),callback:function ($$v) {_vm.$set(_vm.formPasal, "jail", $$v)},expression:"formPasal.jail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3708508447)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Denda"}},[_c('validation-provider',{attrs:{"name":"denda","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"denda","placeholder":"Denda","locale":"id","now-button":"","state":errors.length > 0 ? false : null},model:{value:(_vm.formPasal.denda),callback:function ($$v) {_vm.$set(_vm.formPasal, "denda", $$v)},expression:"formPasal.denda"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3645438967)})],1)],1)],1):_vm._e(),(!_vm.Loading)?_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Submit")]):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-8"},[_c('b-form-group')],1),_c('b-col',{staticClass:"col-4"},[_c('b-form-group',[_c('b-input',{attrs:{"placeholder":"Filter"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1)],1),(!_vm.Loading)?_c('b-row',[_c('b-col',{staticClass:"col-12"},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.filteredTableData,"fields":_vm.fields,"per-page":_vm.perPage,"current-page":_vm.currentPage},scopedSlots:_vm._u([{key:"cell(action)",fn:function(row){return [_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.edit(row.item)}}},[_vm._v("Edit")])]}}],null,false,101887652)}),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-pagination',{attrs:{"total-rows":_vm.filteredTableData.length,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }